import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  clearStudentOpportunityList,
  requestStudentOpportunityList,
  receiveStudentOpportunityList,
  receiveStudentOpportunityListError,
} from './actions';

const initialState = {
  request: null,
  data: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_OPPORTUNITY_LIST:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENT_OPPORTUNITY_LIST:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.STUDENT_OPPORTUNITY_LIST_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.RESET_STUDENT_OPPORTUNITY_LIST:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchStudentsListOpportunity = student_uuid => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentOpportunityList());
  try {
    return fetch(
      `${
        API.student.student_opportunity
      }/?action_type=all&stu_id=${student_uuid}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentOpportunityList(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(receiveStudentOpportunityListError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(receiveStudentOpportunityListError(err));
  }
};

export const resetStudentsListOpportunity = () => dispatch => {
  dispatch(clearStudentOpportunityList());
};
