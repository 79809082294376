import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckCircle = ({color = '#fff', fontSize = '24px', ...props}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      sx={{fontSize: fontSize || '24px', cursor: 'pointer'}}>
      <g id="CheckCircle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none">
          <path
            d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM10.5 17L5.5 12L6.91 10.59L10.5 14.17L18.09 6.58L19.5 8L10.5 17Z"
            fill={color}
          />
        </svg>
      </g>
    </SvgIcon>
  );
};

export default CheckCircle;
