import {combineReducers} from 'redux';
import {jobsReducer} from './jobs/reducer';
import {adminReducer} from './administration/reducer';
import {opportunitiesReducer} from './opportunities/reducer';
import {talentExchangeReducer} from './talentExchange/reducer';
import {apprenticeshipsReducer} from './apprenticeships/reducer';

export const employerReducer = combineReducers({
  jobs: jobsReducer,
  admin: adminReducer,
  opportunities: opportunitiesReducer,
  talentExchange: talentExchangeReducer,
  apprenticeships: apprenticeshipsReducer,
});
