export const REQUEST_STUDENT_ONBOARD_STATS = 'REQUEST_STUDENT_ONBOARD_STATS';
export const RECEIVE_STUDENT_ONBOARD_STATS = 'RECEIVE_STUDENT_ONBOARD_STATS';
export const STUDENT_ONBOARD_STATS_ERROR = 'STUDENT_ONBOARD_STATS_ERROR';

export const REQUEST_STUDENT_ONBOARD_EVENTS = 'REQUEST_STUDENT_ONBOARD_EVENTS';
export const RECEIVE_STUDENT_ONBOARD_EVENTS = 'RECEIVE_STUDENT_ONBOARD_EVENTS';
export const STUDENT_ONBOARD_EVENTS_ERROR = 'STUDENT_ONBOARD_EVENTS_ERROR';

export const REQUEST_STUDENT_ONBOARD_COURSE_MAP =
  'REQUEST_STUDENT_ONBOARD_COURSE_MAP';
export const RECEIVE_STUDENT_ONBOARD_COURSE_MAP =
  'RECEIVE_STUDENT_ONBOARD_COURSE_MAP';
export const STUDENT_ONBOARD_COURSE_MAP_ERROR =
  'STUDENT_ONBOARD_COURSE_MAP_ERROR';
export const STUDENT_ONBOARD_COURSE_MAP_RESET =
  'STUDENT_ONBOARD_COURSE_MAP_RESET';

export const REQUEST_STUDENT_ONBOARD_PATHS = 'REQUEST_STUDENT_ONBOARD_PATHS';
export const RECEIVE_STUDENT_ONBOARD_PATHS = 'RECEIVE_STUDENT_ONBOARD_PATHS';
export const STUDENT_ONBOARD_PATHS_ERROR = 'STUDENT_ONBOARD_PATHS_ERROR';
export const STUDENT_ONBOARD_PATHS_RESET = 'STUDENT_ONBOARD_PATHS_RESET';

export const REQUEST_STUDENT_ASSESSMENTS = 'REQUEST_STUDENT_ASSESSMENTS';
export const RECEIVE_STUDENT_ASSESSMENTS = 'RECEIVE_STUDENT_ASSESSMENTS';
export const STUDENT_ASSESSMENTS_ERROR = 'STUDENT_ASSESSMENTS_ERROR';

export const REQUEST_STUDENT_PROFILE = 'REQUEST_STUDENT_PROFILE';
export const RECEIVE_STUDENT_PROFILE = 'RECEIVE_STUDENT_PROFILE';
export const STUDENT_PROFILE_ERROR = 'STUDENT_PROFILE_ERROR';

export const REQUEST_STUDENT_PROFILE_DETAILS =
  'REQUEST_STUDENT_PROFILE_DETAILS';
export const RECEIVE_STUDENT_PROFILE_DETAILS =
  'RECEIVE_STUDENT_PROFILE_DETAILS';
export const STUDENT_PROFILE_DETAILS_ERROR = 'STUDENT_PROFILE_DETAILS_ERROR';
export const CLEAR_STUDENT_PROFILE_DETAILS = 'CLEAR_STUDENT_PROFILE_DETAILS';

export const REQUEST_STUDENT_RECOMMENDED_PLAN =
  'REQUEST_STUDENT_RECOMMENDED_PLAN';
export const RECEIVE_STUDENT_RECOMMENDED_PLAN =
  'RECEIVE_STUDENT_RECOMMENDED_PLAN';
export const STUDENT_RECOMMENDED_PLAN_ERROR = 'STUDENT_RECOMMENDED_PLAN_ERROR';

export const CLEAR_STUDENT_PROFILE_DATA = 'CLEAR_STUDENT_PROFILE_DATA';
export const RECEIVE_STUDENT_PROFILE_DATA = 'RECEIVE_STUDENT_PROFILE_DATA';
export const REQUEST_STUDENT_PROFILE_DATA = 'REQUEST_STUDENT_PROFILE_DATA';
export const STUDENT_PROFILE_DATA_ERROR = 'STUDENT_PROFILE_DATA_ERROR';

export const REQUEST_STUDENT_SKILS_STATUS = 'REQUEST_STUDENT_SKILS_STATUS';
export const RECEIVE_STUDENT_SKILS_STATUS = 'RECEIVE_STUDENT_SKILS_STATUS';
export const STUDENT_SKILS_STATUS_ERROR = 'STUDENT_SKILS_STATUS_ERROR';

export const REQUEST_SKILLS_DATA = 'REQUEST_SKILLS_DATA';
export const RECEIVE_SKILLS_DATA = 'RECEIVE_SKILLS_DATA';
export const SKILLS_DATA_ERROR = 'SKILLS_DATA_ERROR';

export const REQUEST_STUDENT_WORKFORCE = 'REQUEST_STUDENT_WORKFORCE';
export const RECEIVE_STUDENT_WORKFORCE = 'RECEIVE_STUDENT_WORKFORCE';
export const STUDENT_WORKFORCE_ERROR = 'STUDENT_WORKFORCE_ERROR';

export const REQUEST_STUDENT_SAVED_PATHS = 'REQUEST_STUDENT_SAVED_PATHS';
export const RECEIVE_STUDENT_SAVED_PATHS = 'RECEIVE_STUDENT_SAVED_PATHS';
export const STUDENT_SAVED_PATHS_ERROR = 'STUDENT_SAVED_PATHS_ERROR';
export const STUDENT_SAVED_PATHS_RESET = 'STUDENT_SAVED_PATHS_RESET';

export const REQUEST_STUDENT_COMPARED_PATHS = 'REQUEST_STUDENT_COMPARED_PATHS';
export const RECEIVE_STUDENT_COMPARED_PATHS = 'RECEIVE_STUDENT_COMPARED_PATHS';
export const STUDENT_SAVED_COMPARED_ERROR = 'STUDENT_SAVED_COMPARED_ERROR';
export const STUDENT_SAVED_COMPARED_RESET = 'STUDENT_SAVED_COMPARED_RESET';

export const STUDENT_AWARD_TYPES_LOAD = 'STUDENT_AWARD_TYPES_LOAD';
export const STUDENT_AWARD_TYPES_SUCCESS = 'STUDENT_AWARD_TYPES_SUCCESS';
export const STUDENT_AWARD_TYPE_ERROR = 'STUDENT_AWARD_TYPE_ERROR';

export const STUDENT_CLUSTER_TYPES_LOAD = 'STUDENT_CLUSTER_TYPES_LOAD';
export const STUDENT_CLUSTER_TYPES_SUCCESS = 'STUDENT_CLUSTER_TYPES_SUCCESS';
export const STUDENT_CLUSTER_TYPES_ERROR = 'STUDENT_CLUSTER_TYPES_ERROR';

//Resume List
export const REQUEST_STUDENT_RESUME_LIST = 'REQUEST_STUDENT_RESUME_LIST';
export const RECEIVE_STUDENT_RESUME_LISTS = 'RECEIVE_STUDENT_RESUME_LIST';
export const STUDENT_RESUME_LIST_ERROR = 'STUDENT_RESUME_LIST_ERROR';
export const CLEAR_STUDENT_RESUME_LIST = 'CLEAR_STUDENT_RESUME_LIST';

//privacy settings
export const REQUEST_PRIVACY_SETTING = 'REQUEST_PRIVACY_SETTING';
export const RECEIVE_PRIVACY_SETTING = 'RECEIVE_PRIVACY_SETTING';
export const HANDLE_PRIVACY_SETTING_ERROR = 'HANDLE_PRIVACY_SETTING_ERROR';
export const CLEAR_PRIVACY_SETTING = 'CLEAR_PRIVACY_SETTING';

//careerNav
export const REQUEST_CAREERNAV_USERS = 'REQUEST_CAREERNAV_USERS';
export const RECEIVE_CAREERNAV_USERS = 'RECEIVE_CAREERNAV_USERS';
export const CAREERNAV_USERS_ERROR = 'CAREERNAV_USERS_ERROR';

// student opportunity
export const REQUEST_STUDENT_OPPORTUNITY_LIST =
  'REQUEST_STUDENT_OPPORTUNITY_LIST';
export const RECEIVE_STUDENT_OPPORTUNITY_LIST =
  'RECEIVE_STUDENT_OPPORTUNITY_LIST';
export const STUDENT_OPPORTUNITY_LIST_ERROR = 'STUDENT_OPPORTUNITY_LIST_ERROR';
export const RESET_STUDENT_OPPORTUNITY_LIST = 'RESET_STUDENT_OPPORTUNITY_LIST';

// student saved careers
export const REQUEST_STUDENT_SAVED_CAREERS = 'REQUEST_STUDENT_SAVED_CAREERS';
export const RECEIVE_STUDENT_SAVED_CAREERS = 'RECEIVE_STUDENT_SAVED_CAREERS';
export const STUDENT_SAVED_CAREERS_ERROR = 'STUDENT_SAVED_CAREERS_ERROR';
export const STUDENT_SAVED_CAREERS_RESET = 'STUDENT_SAVED_CAREERS_RESET';

// student survey results
export const REQUEST_STUDENT_SURVEY_RESULTS = 'REQUEST_STUDENT_SURVEY_RESULTS';
export const RECEIVE_STUDENT_SURVEY_RESULTS = 'RECEIVE_STUDENT_SURVEY_RESULTS';
export const STUDENT_SURVEY_RESULTS_ERROR = 'STUDENT_SURVEY_RESULTS_ERROR';
export const STUDENT_SURVEY_RESULTS_RESET = 'STUDENT_SURVEY_RESULTS_RESET';

// student fit survey results
export const REQUEST_STUDENT_FIT_SURVEY_RESULTS =
  'REQUEST_STUDENT_FIT_SURVEY_RESULTS';
export const RECEIVE_STUDENT_FIT_SURVEY_RESULTS =
  'RECEIVE_STUDENT_FIT_SURVEY_RESULTS';
export const STUDENT_FIT_SURVEY_RESULTS_ERROR =
  'STUDENT_FIT_SURVEY_RESULTS_ERROR';
export const STUDENT_FIT_SURVEY_RESULTS_RESET =
  'STUDENT_FIT_SURVEY_RESULTS_RESET';
//student resume
export const REQUEST_STUDENT_RESUME = 'REQUEST_STUDENT_RESUME';
export const RECEIVE_STUDENT_RESUME = 'RECEIVE_STUDENT_RESUME';
export const STUDENT_RESUME_ERROR = 'STUDENT_RESUME_ERROR';
export const CLEAR_STUDENT_RESUME = 'CLEAR_STUDENT_RESUME';
