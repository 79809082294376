import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// components
import {
  Box,
  Chip,
  Stack,
  styled,
  Tooltip,
  Divider,
  useTheme,
  InputBase,
  Typography,
  IconButton,
  FormHelperText,
} from '@mui/material';
import Button from 'mui-core/Button';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CropFreeRoundedIcon from '@mui/icons-material/CropFreeRounded';
// redux
import {sendMessageClose} from 'redux/modules/general';
import {getSendMail} from 'redux/modules/general/selectors';
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded';
// util
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useAuth, useColor} from 'mui-core/hooks';
import {JSON_HEADER, JWT_HEADER, API} from 'config/srm.config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextField = styled(InputBase)(({theme, color}) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& textarea': {
    paddingLeft: '0px !important',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'transparent',
    fontSize: 16,
    padding: '10px 12px',
    color: useColor(
      theme.palette.text.primary,
      theme.palette.text.contrastText
    ),
    '&:focus': {
      boxShadow: 'none',
      borderColor: 'transparent',
    },
  },
}));

const SendMessage = () => {
  const {token} = useAuth();
  const theme = useTheme();
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const mail = useSelector(getSendMail);
  const {recipients} = mail;
  const dispatch = useDispatch();
  const color = useColor(
    theme.palette.text.primary,
    theme.palette.text.contrastText
  );

  const {enqueueSnackbar} = useSnackbar();
  const formik = useFormik({
    initialValues: {
      title: '',
      text: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(5, 'Please enter atleast 5 characters')
        .max(70, 'Subject can takem only 2500 characters limit')
        .required('Title is required'),
      text: Yup.string()
        .min(5, 'Please enter atleast 5 characters')
        .max(2500, 'Message can takem only 2500 characters limit')
        .required('Message is required'),
    }),
    onSubmit: values => {
      postData(values);
    },
  });
  const postData = async formData => {
    let requestdata = {
      ...formData,
      recipients: recipients.map(recipient => recipient.user_account),
    };
    setLoading(true);
    try {
      const config = {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(requestdata),
        headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
      };
      const response = await (await fetch(
        API.institution.message_staff,
        config
      )).json();
      if (response.Success || response.success) {
        enqueueSnackbar('Message successfully sent.', {
          variant: 'success',
        });
        if (mail?.callback && typeof mail.callback === 'function') {
          setTimeout(() => {
            mail.callback();
          }, 300);
        }
        close();
        setLoading(false);
      } else {
        throw Error('Something went wrong with api, Try again later');
      }
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
        close();
        setLoading(false);
      }
    }
  };
  const close = () => {
    dispatch(sendMessageClose());
    setTimeout(() => {
      setFullScreen(false);
      formik.resetForm();
      formik.submitCount = 0;
    }, 400);
  };

  return (
    <form>
      <Dialog
        open={mail.open}
        maxWidth={'md'}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" color={color}>
            New Message
          </Typography>
        </DialogTitle>
        <Tooltip title={fullScreen ? 'Minimize' : 'Maximize'}>
          <IconButton
            aria-label="close"
            onClick={() => setFullScreen(!fullScreen)}
            sx={theme => ({
              top: 8,
              right: 50,
              position: 'absolute',
              color: theme.palette.grey[600],
            })}>
            {fullScreen ? (
              <CloseFullscreenRoundedIcon />
            ) : (
              <CropFreeRoundedIcon />
            )}
          </IconButton>
        </Tooltip>

        <IconButton
          aria-label="close"
          onClick={close}
          sx={theme => ({
            top: 8,
            right: 8,
            position: 'absolute',
            color: theme.palette.grey[600],
          })}>
          <CloseRoundedIcon />
        </IconButton>
        <DialogContent sx={{minWidth: '600px', padding: 0}}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mx: 3,
              py: 1,
              alignItems: 'center',
            }}>
            <Typography sx={{minWidth: '58px'}} color={color}>
              To:
            </Typography>
            <Stack direction="row" sx={{flexWrap: 'wrap'}} color={color}>
              {recipients.map(user => (
                <Chip
                  label={user.email}
                  sx={{marginTop: '5px', marginRight: '5px', color: {color}}}
                />
              ))}
            </Stack>
          </Stack>
          <Divider />
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mx: 3,
              py: 1,
              alignItems: 'center',
            }}>
            <Typography color={color}>Subject:</Typography>
            <Box
              sx={{
                flexGrow: 1,
                position: 'relative',
              }}>
              <TextField
                fullWidth
                name="title"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.title}
                placeholder="Enter Subject"
              />
              {formik?.errors?.title && formik.submitCount > 0 && (
                <FormHelperText
                  error
                  sx={{position: 'absolute', bottom: '-9px', left: '13px'}}>
                  {formik?.errors?.title}
                </FormHelperText>
              )}
            </Box>
          </Stack>
          <Divider />
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mx: 3,
              py: 1,
              alignItems: 'center',
            }}>
            <Box
              sx={{
                flexGrow: 1,
                position: 'relative',
              }}>
              <TextField
                multiline
                name="text"
                fullWidth
                minRows={7}
                color={color}
                onChange={formik.handleChange}
                value={formik.values.text}
                maxRows={fullScreen ? 30 : 15}
                size="small"
                placeholder="Write something.."
              />
              {formik?.errors?.text && formik.submitCount > 0 && (
                <FormHelperText
                  error
                  sx={{position: 'absolute', bottom: '-9px'}}>
                  {formik?.errors?.text}
                </FormHelperText>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            disabled={loading}
            color="primary">
            {loading ? 'Sending' : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default SendMessage;
