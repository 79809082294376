import {
  Box,
  Stack,
  Avatar,
  Divider,
  useTheme,
  CardMedia,
  Typography,
  CardContent,
  CardActions,
  CardActionArea,
  Card as MuiCard,
} from '@mui/material';
// utils
import {alpha, styled} from '@mui/material/styles';
import {GPS_DOMAIN} from './../../config/srm.config';
import {getBanner, themeColorMode, numberInUSFormat} from 'mui-core/utils';

const TagContainer = styled(Stack)(() => ({
  top: 20,
  left: 0,
  zIndex: 1,
  width: 120,
  position: 'absolute',
}));

const tagTypeColor = tagType => {
  switch (tagType) {
    case 'inDemand': {
      return {background: 'var(--mui-palette-teal600-main)', color: 'white'};
    }
    case 'popular': {
      return {background: 'var(--mui-palette-primary-main)', color: 'white'};
    }
    case 'newPath': {
      return {background: 'var(--mui-palette-blue600-main)', color: 'white'};
    }
    case 'online': {
      return {
        background: 'var(--mui-palette-fuchsia600-main)',
        color: 'white',
      };
    }
    case 'Enrolled': {
      return {background: 'var(--mui-palette-orange-main)', color: 'black'};
    }
    case 'Saved': {
      return {
        background: 'var(--mui-palette-lightBlue-main)',
        color: 'black',
      };
    }
    default:
      return {};
  }
};

const Tag = styled(Box)(({type}) => ({
  display: 'flex',
  padding: '4px 0px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0 12px 12px 0',
  ...tagTypeColor(type),
}));

const LogoWrapper = styled(Box)(({theme}) => ({
  left: '20px',
  top: '144px',
  position: 'absolute',
  '& img': {
    width: 72,
    height: 72,
    maxWidth: '100%',
    objectFit: 'contain',
    borderRadius: '100%',
    border: '1px solid rgba(0,0,0, 0.08)',
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledCard = styled(MuiCard)(({theme}) => ({
  minWidth: '320px',
  maxWidth: '320px',
  borderRadius: '12px',
  border: `1px solid ${alpha('#000', 0.1)}`,
  boxShadow: `0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)`,
  '&:hover, &.Mui-focusVisible, &:focus-visible': {
    transform: 'scale(1.02)',
    transition: 'all 0.4s linear',
    boxShadow: `0px 4px 8px -2px ${
      theme.palette.mode === 'light'
        ? theme.palette.grayBorder.main
        : theme.palette.text.secondary
    }`,
    border: `2px solid var(--mui-palette-primary-main)`,
  },
}));

const renderMetrics = (theme, metrics) => {
  return (
    <Stack
      rowGap={1}
      columnGap={1.5}
      flexWrap="wrap"
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        overflowY: 'auto',
        maxHeight: '90px',
      }}>
      {metrics?.map(({Icon, count = 0, label}, index) => {
        let countValue = count;
        if (countValue >= 10000 && label !== 'Salary') {
          countValue = '10,000+';
        } else {
          countValue = numberInUSFormat(count);
        }
        return (
          <Stack
            spacing={0.5}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            key={`metric-${index + 1}`}
            justifyContent="flex-start">
            <Icon
              fontSize={{xs: '16px', md: '20px'}}
              color="var(--mui-palette-Gray400-main)"
            />
            <Typography
              variant="label-sm"
              sx={{
                color: 'var(--mui-palette-teal-main)',
              }}>
              {countValue}
            </Typography>
            <Typography
              variant="label-sm"
              sx={{
                color: themeColorMode(
                  theme.palette.gray400.main,
                  theme.palette.gray400.main.contrastText
                ),
              }}>
              {label}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

function CareerCard({details = {}}) {
  const {
    path = '',
    logo = '',
    title = '',
    banner = '',
    subTitle = '',
    metrics = [], // [{ Icon, count, label }]
    infoStack = [], // [{ InfoIcon, infoLabel }]
    tags: {customTag, popular, newPath, inDemand, online} = {},
  } = details || {};
  const theme = useTheme();

  const navigateTo = () => {
    window.open(`https://${GPS_DOMAIN}/${path}`);
  };
  // const defautlGoBanner =
  //   'https://res.cloudinary.com/goeducate-inc/image/upload/q_auto/gps-graphics/defaultCard_ws7ftz.png';

  return (
    <StyledCard elevation={2} component={CardActionArea} onClick={navigateTo}>
      <Box position="relative">
        <Box sx={{height: 180, overflow: 'hidden'}}>
          <CardMedia
            title={title}
            sx={{height: 180}}
            image={getBanner(banner)}
          />
        </Box>
        {logo && (
          <LogoWrapper>
            <Avatar src={logo} aria-label="logo" sx={{width: 72, height: 72}} />
          </LogoWrapper>
        )}
        <TagContainer spacing={0.5}>
          {customTag ? (
            <>
              <Tag type={customTag}>
                <Typography variant="text-sm-regular">{customTag}</Typography>
              </Tag>
            </>
          ) : (
            <>
              {inDemand && (
                <Tag type="inDemand">
                  <Typography variant="text-sm-regular">In-Demand</Typography>
                </Tag>
              )}
              {online && (
                <Tag type="online">
                  <Typography variant="text-sm-regular">Online</Typography>
                </Tag>
              )}
              {popular && (
                <Tag type="popular">
                  <Typography variant="text-sm-regular">Popular</Typography>
                </Tag>
              )}
              {newPath && (
                <Tag type="newPath">
                  <Typography variant="text-sm-regular">New</Typography>
                </Tag>
              )}
            </>
          )}
        </TagContainer>
      </Box>

      <CardContent sx={{p: 2.5, pt: logo ? 6 : 2.5}}>
        <Stack spacing={{xs: 1.5, md: 2.5}}>
          {title && (
            <Typography
              title={title}
              component="h5"
              variant="text-lg-bold"
              sx={{
                overflow: 'hidden',
                WebkitLineClamp: '2',
                display: '-webkit-box',
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                height: {xs: 40, md: 58},
                WebkitBoxOrient: 'vertical',
                textTransform: 'capitalize',
                color: themeColorMode(
                  theme,
                  theme.palette.text.primary,
                  theme.palette.text.contrastText
                ),
              }}>
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              component="p"
              title={subTitle}
              variant="text-md-regular"
              sx={{
                overflow: 'hidden',
                WebkitLineClamp: '1',
                display: '-webkit-box',
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
                height: {xs: 16, md: 24},
                WebkitBoxOrient: 'vertical',
                textTransform: 'capitalize',
                color: themeColorMode(
                  theme,
                  theme.palette.text.primary,
                  theme.palette.text.contrastText
                ),
              }}>
              {subTitle}
            </Typography>
          )}
          {infoStack && Array.isArray(infoStack) && infoStack?.length > 0 && (
            <Stack spacing={1}>
              {infoStack?.map(({InfoIcon, infoLabel}, index) => (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  key={`info-${index + 1}`}
                  justifyContent="flex-start">
                  <InfoIcon
                    fontSize={{xs: '20px', md: '24px'}}
                    color="var(--mui-palette-primary-main)"
                  />
                  <Typography
                    component="p"
                    title={infoLabel}
                    variant="text-sm-regular"
                    sx={{
                      overflow: 'hidden',
                      WebkitLineClamp: '1',
                      display: '-webkit-box',
                      wordBreak: 'break-all',
                      textOverflow: 'ellipsis',
                      WebkitBoxOrient: 'vertical',
                      color: themeColorMode(
                        theme,
                        theme.palette.text.primary,
                        theme.palette.text.contrastText
                      ),
                    }}>
                    {infoLabel}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </CardContent>

      {metrics && Array.isArray(metrics) && metrics?.length > 0 && (
        <>
          <Divider variant="middle" />
          <CardActions sx={{p: 2.5}}>
            {renderMetrics(theme, metrics)}
          </CardActions>
        </>
      )}
    </StyledCard>
  );
}

export default CareerCard;
