import {useState} from 'react';
// components
import {
  Box,
  Alert,
  Stack,
  Avatar,
  Button,
  styled,
  Tooltip,
  useTheme,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {Grid, ContentBox} from 'mui-core';
import ResumePreview from './ResumePreview';
import NoDataPreview from './NoDataPreview';
import {LocationOn, InsertDriveFile, Download} from '@mui/icons-material';
// utils
import {
  xLogoBlack,
  tiktokBlack,
  linkedInBlack,
  faceBookBlack,
  instagramBlack,
  mediumLogoBlack,
} from 'sharedResources/assets/images';
import {isArray, getGoBanner, getCloudinaryImage} from 'mui-core/utils';
import profilePreview from 'data/profilePreview.json';

const {
  noDataPreviewHeading,
  resumePreviewHeading,
  workPreferences: {openTo = {}, employeeStatus = {}} = {},
} = profilePreview;
const {aJob, anApprenticeship, anInternship} = openTo;
const {openToWork, openToAnApprenticeship, openToAnInternship} = employeeStatus;

const ProfileDetailsContainer = styled(Box)(() => ({
  zIndex: '2',
  display: 'flex',
  marginLeft: '32px',
  alignItems: 'start',
  position: 'relative',
  justifyContent: 'flex-start',
}));

const AvatarContainer = styled(Avatar)(() => ({
  zIndex: 9,
  display: 'flex',
  position: 'relative',
  alignContent: 'center',
  justifyContent: 'center',
}));

const HandelDocuments = ({documents = [], title, filterBy, url = null}) => {
  const filteredDocs = documents.filter(doc => doc?.doc_type === filterBy);

  return (
    <Stack>
      {((filteredDocs && filteredDocs.length > 0) || url) && (
        <Typography
          pl={0.5}
          lineHeight={2}
          variant="title-xs-bold"
          textTransform="capitalize"
          sx={{
            color: theme =>
              theme.palette.mode === 'light' ? 'black' : 'white',
          }}>
          {title}:
        </Typography>
      )}
      {url && (
        <Tooltip title={url}>
          <Typography
            href={url}
            component="a"
            maxWidth={180}
            target="_blank"
            download="file"
            variant="label-md"
            sx={{
              overflow: 'hidden',
              paddingLeft: '12px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textDecoration: 'underline',
            }}>
            {url}
          </Typography>
        </Tooltip>
      )}
      {filteredDocs &&
        filteredDocs.length > 0 &&
        filteredDocs.map(doc => (
          <Stack key={doc?.doc_uuid}>
            <Stack flexDirection="row">
              <Button
                variant="text"
                target="_blank"
                color="secondary"
                href={doc?.doc_link}
                startIcon={<InsertDriveFile />}>
                <Typography variant="label-md">{doc?.doc_name}</Typography>
              </Button>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

const TagContainer = styled(Stack)(() => ({
  top: 32,
  left: 0,
  zIndex: 1,
  position: 'absolute',
}));

const Tag = styled(Alert)(() => ({
  padding: '4px 24px',
  borderRadius: '0 42px 42px 0',
}));

const ProfilePreview = ({
  data,
  seekingTag,
  resumeList,
  profileData,
  handleResumeDownload,
}) => {
  const theme = useTheme();
  const bannerImage = getGoBanner(data?.banner);
  const [isExpanded, setIsExpanded] = useState(false);
  const profileImage = getCloudinaryImage(data?.photo, 'logo');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const {data: userTypes = []} = useSelector(getUserTypesSelector);
  const resumeId =
    (isArray(resumeList) &&
      resumeList.length > 0 &&
      resumeList[0].resume_uuid) ||
    '';
  const cognitoUserName = data?.cognito_username;
  const userType = data?.user_type;
  // userTypes?.find(
  //   user => user.user_type_uuid === data?.user_type
  // );
  const resumeSummary =
    (isArray(resumeList) && resumeList.length > 0 && resumeList[0].summary) ||
    '';
  const resumeName =
    (isArray(resumeList) &&
      resumeList.length > 0 &&
      resumeList[0].resume_name) ||
    '';

  const toggleSummary = () => {
    setIsExpanded(prev => !prev);
  };

  const SocialIconsButton = props => {
    const {link, src, alt} = props;
    return (
      <IconButton
        size="small"
        href={link}
        target="_blank"
        sx={theme => ({
          filter: `invert(${0})`,
          ...theme.applyStyles('dark', {
            filter: `invert(${1})`,
          }),
        })}>
        <img
          src={src}
          alt={alt}
          style={{maxHeight: isMobile && '14px', maxWidth: isMobile && '14px'}}
        />
      </IconButton>
    );
  };

  const student_information =
    (profileData &&
      Array.isArray(profileData) &&
      profileData.length > 0 &&
      profileData[0]) ||
    {};

  const documentTypes = [
    {
      type: 'transcript',
      title: 'Transcripts',
      documentData: student_information?.documents,
    },
    {
      type: 'cover-letter',
      title: 'Cover Letter',
      documentData: student_information?.documents,
    },
    {
      type: 'portfolio',
      title: 'Portfolio link',
      url: student_information?.portfolio_url,
      documentData: student_information?.documents,
    },
  ];

  let employmentStatus = '';

  if (seekingTag === aJob) {
    employmentStatus = openToWork;
  } else if (seekingTag === anApprenticeship) {
    employmentStatus = openToAnApprenticeship;
  } else if (seekingTag === anInternship) {
    employmentStatus = openToAnInternship;
  }

  return (
    <Box>
      <Stack sx={{position: 'relative'}}>
        <img
          width="100%"
          height="215px"
          src={bannerImage}
          style={{
            padding: '0px !important',
            borderRadius: '20px 20px 0px 0px',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
          }}
        />
        {employmentStatus && (
          <TagContainer>
            <Tag severity="info" variant="filled">
              <Typography variant="caption">{employmentStatus}</Typography>
            </Tag>
          </TagContainer>
        )}
        <Stack
          p={3}
          spacing={3}
          direction="row"
          sx={{
            bgcolor: theme =>
              theme.palette.mode === 'light'
                ? 'white'
                : theme.palette.darkGray.main,
          }}
          alignItems="center">
          <Box>
            <AvatarContainer
              alt="avatar-logo"
              src={profileImage}
              sx={{
                border: '1px solid white',
                width: {xs: '80px', sm: '150px'},
                height: {xs: '80px', sm: '150px'},
              }}
            />
            <Typography
              component="p"
              variant="text-sm-medium"
              sx={{
                textAlign: 'center',
                mt: 2,
                color: theme =>
                  theme.palette.mode === 'light' ? 'text.secondary' : 'white',
              }}>
              {userType}
              {/* {userType?.user_type} */}
            </Typography>
          </Box>
          <Stack gap={0.4} pt={0.3}>
            {data?.first_name && (
              <Typography
                sx={{
                  color: theme =>
                    theme.palette.mode === 'light' ? 'black' : 'white',
                }}
                component="h6"
                lineHeight={1.5}
                variant="title-sm-bold"
                textTransform="capitalize">
                {`${data?.first_name} ${data?.last_name}`}
              </Typography>
            )}
            {data?.career_headline && (
              <Typography
                pl={0.5}
                component="p"
                variant="text-sm-bold"
                sx={{
                  color: theme => theme.palette.grey.main,
                }}>
                {data?.career_headline}
              </Typography>
            )}
            {data?.address?.city && data?.address?.state && (
              <Stack direction="row" alignItems="center">
                <LocationOn
                  sx={theme => ({
                    color: theme.palette.text.secondary,
                    fontSize: isMobile ? 'small' : '20px',
                    ...theme.applyStyles('dark', {
                      color: theme.palette.darkGray.contrastText,
                    }),
                  })}
                />
                <Typography
                  component="p"
                  variant="text-xs-regular"
                  textTransform="capitalize"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: theme => theme.palette.grey.main,
                  }}>
                  {data?.address?.city + ', ' + data?.address?.state}
                </Typography>
              </Stack>
            )}
            {resumeSummary && (
              <Box>
                <Typography
                  component="h4"
                  variant="title-xs-bold"
                  sx={{
                    my: 1,
                    mb: 0.2,
                    color: theme =>
                      theme.palette.mode === 'light' ? 'black' : 'white',
                  }}>
                  About Me
                </Typography>
                <Box>
                  <Typography
                    component="p"
                    variant="text-xs-regular"
                    sx={{
                      color: theme => theme.palette.grey.main,
                    }}>
                    {resumeSummary && resumeSummary.length > 500
                      ? isExpanded
                        ? resumeSummary
                        : resumeSummary.slice(0, 500) + '...'
                      : resumeSummary}
                  </Typography>
                  {resumeSummary && resumeSummary.length > 500 && (
                    <Button onClick={toggleSummary} size="small">
                      {isExpanded ? 'Show Less' : 'Show More'}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            <Stack gap={0.5} direction="row">
              {data && data.facebook_link && data.show_facebook_link && (
                <SocialIconsButton
                  alt="fb"
                  src={faceBookBlack}
                  link={data.facebook_link}
                />
              )}

              {data && data.twitter_link && data.show_twitter_link && (
                <SocialIconsButton
                  alt="tw"
                  src={xLogoBlack}
                  link={data.twitter_link}
                />
              )}

              {data && data.linkedin_link && data.show_linkedin_link && (
                <SocialIconsButton
                  alt="li"
                  src={linkedInBlack}
                  link={data.linkedin_link}
                />
              )}

              {data && data.instagram_link && data.show_instagram_link && (
                <SocialIconsButton
                  alt="ig"
                  src={instagramBlack}
                  link={data.instagram_link}
                />
              )}

              {data && data.tiktok_link && data.show_tiktok_link && (
                <SocialIconsButton
                  alt="tt"
                  src={tiktokBlack}
                  link={data.tiktok_link}
                />
              )}

              {data && data.medium_link && data.show_medium_link && (
                <SocialIconsButton
                  alt="med"
                  src={mediumLogoBlack}
                  link={data.medium_link}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          bgcolor: theme =>
            theme.palette.mode === 'light'
              ? 'white'
              : theme.palette.darkGray.main,
        }}>
        <Grid container mx={3}>
          {documentTypes.map((doc, index) => {
            return (
              <Grid
                key={index}
                display="flex"
                justifyContent={{xs: 'left', md: 'center'}}
                size={{
                  sm: 5,
                  md: 4,
                  lg: 4,
                  xs: 12,
                }}>
                <HandelDocuments
                  url={doc?.url}
                  title={doc?.title}
                  filterBy={doc?.type}
                  documents={doc?.documentData}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            height: '100%',
            borderRadius: '10px',
            position: 'relative',
          }}>
          <ContentBox sx={{m: 3}}>
            {isArray(resumeList) &&
            resumeList.length > 0 &&
            resumeList[0].resume_uuid ? (
              <>
                <Stack
                  sx={{
                    right: 50,
                    zIndex: 9,
                    marginTop: '9px',
                    position: 'absolute',
                  }}>
                  <Button
                    size="medium"
                    color="primary"
                    title="Download"
                    aria-label="download"
                    startIcon={<Download fontSize="medium" sx={{mt: 0.2}} />}
                    onClick={() =>
                      handleResumeDownload(
                        resumeId,
                        cognitoUserName,
                        resumeName
                      )
                    }>
                    Download
                  </Button>
                </Stack>
                <ResumePreview
                  disableScroll
                  isShareProfile
                  disableEyeIcon
                  isMobile={isMobile}
                  data={resumeList[0]}
                  heading={resumePreviewHeading}
                />
              </>
            ) : (
              <NoDataPreview
                disableEyeIcon
                isShareProfile
                heading={noDataPreviewHeading}
              />
            )}
          </ContentBox>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfilePreview;
