export const studentOnboardStats = ({studentManagement: {students}}) =>
  students;

export const studentProfileSelector = ({studentManagement: {studentProfile}}) =>
  studentProfile;

export const studentProfileData = ({
  studentManagement: {
    studentProfile: {
      data: {Data = {}},
    },
  },
}) => Data;

export const studentOnboardEvents = ({
  studentManagement: {
    studentProfile: {
      data: {signedup_events = []},
    },
  },
}) => signedup_events;

export const studentOnboardPaths = ({
  studentManagement: {
    studentProfile: {
      data: {mypaths = {}},
    },
  },
}) => mypaths;

export const studentOnboardCarrerPlan = ({
  studentManagement: {
    studentProfile: {
      data: {course_map = {}},
    },
  },
}) => course_map;

export const studentSkillsSelector = state =>
  state.studentManagement.studentSkills;

export const skillsDataSelector = state => state.studentManagement.skills;

export const getStudentProfileDataSelector = state =>
  state.studentManagement.studentProfileData;

export const getStudentWorkforceSelector = state =>
  state.studentManagement.studentWorkforce;

export const studentComparedPathsSelector = state =>
  state.studentManagement.studentComparedPaths;

export const studentAwardTypesSelector = ({
  studentManagement: {studentAwardTypes},
}) => studentAwardTypes;

export const clusterTypesSelector = state =>
  state.studentManagement.clusterTypes;

// new students details
export const getStudentResumeList = state =>
  state && state.studentManagement && state.studentManagement.studentResumeList;

export const getStudentPrivacySettings = state =>
  state && state.studentManagement && state.studentManagement.privacySettings;

export const getCareerNavUsers = state =>
  state && state.studentManagement.careerNavUsers;

// student enrolled
export const getStudentPathsSelector = state =>
  state && state.studentManagement.studentPaths;

// saved
export const studentSavedPathsSelector = state =>
  state.studentManagement.studentSavedPaths;

// opportunity
export const getStudentOpportunityListSelector = state =>
  state.studentManagement.studentOpportunity;

// Saved Careers
export const getSavedCareers = state =>
  state.studentManagement.studentSavedCareers;

// Survey results
export const getGoSurveyResults = state =>
  state.studentManagement.studentSurveyResults;

// Fit Survey results
export const getFitAssessmentSurvey = state =>
  state.studentManagement.studentFitAssessmentSurvey;
export const getStudentResume = state =>
  state && state.studentManagement.studentResume;
