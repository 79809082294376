import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';
import * as TYPES from './constants';
import {
  requestStudentSavedCareers,
  receiveStudentSavedCareers,
  handleStudentSavedCareersError,
  clearStudentSavedCareers,
} from './actions';
const initialState = {
  request: null,
  data: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_SAVED_CAREERS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_STUDENT_SAVED_CAREERS:
      return {
        ...state,
        data: action.payload,
        request: false,
      };
    case TYPES.STUDENT_SAVED_CAREERS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    case TYPES.STUDENT_SAVED_CAREERS_RESET:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const fetchSavedCareers = student_uuid => (dispatch, getState) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  dispatch(requestStudentSavedCareers());
  try {
    return fetch(
      `${API.student.saved_career}?student_uuid=${student_uuid}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(json => {
        dispatch(receiveStudentSavedCareers(json));
      })
      .catch(err => {
        console.log(err);
        dispatch(handleStudentSavedCareersError(err));
      });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentSavedCareersError(err));
  }
};

export const resetStudentSavedCareers = () => dispatch => {
  dispatch(clearStudentSavedCareers());
};
