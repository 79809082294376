/* eslint no-use-before-define: 0 */
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {Box, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';

const ChartWrapper = styled(Box)(() => ({
  [`& .apexcharts-xaxis-label:hover`]: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const BarChart = ({labels = [], data = {}, onActionClick, onLabelClick}) => {
  const theme = useTheme();
  const barChart = {
    series: [
      {
        data: data,
        name: 'score',
      },
    ],
  };

  const LabelInfo =
    labels && Array.isArray(labels) && labels.length > 0 ? labels : [];

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      toolbar: {
        show: false,
      },
      events: {
        xAxisLabelClick: function(_event, _chartContext, config) {
          onClickLabel(config.config.xaxis.categories[config.labelIndex]);
        },
        dataPointSelection: function(_event, _chartContext, config) {
          onClickLabel(config.w.config.xaxis.categories[config.dataPointIndex]);
        },
      },
    },
    fill: {
      opacity: 1.5,
      type: 'solid',
      colors: theme.palette.primary.main,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: LabelInfo.length < 3 ? '5px' : '20px',
        dataLabels: {
          position: 'center', // top, center, bottom
          style: {
            fontSize: '12px',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
    },
    xaxis: {
      min: 5,
      position: 'bottom',
      categories: LabelInfo,
      labels: {
        show: true,
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontSize: '14px',
          colors: theme.palette.primary.main,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
  };

  const onClickLabel = label => {
    if (onLabelClick && label) onLabelClick(label);
  };

  const onClickAction = (idx, itm) => {
    if (onActionClick && itm) onActionClick(idx, itm);
  };

  return (
    <ChartWrapper>
      <Chart
        type="bar"
        width="100%"
        height={350}
        borderRadius="8px"
        options={chartOptions}
        series={barChart.series}
      />
    </ChartWrapper>
  );
};

BarChart.propTypes = {
  onLabelClick: PropTypes.func,
  onActionClick: PropTypes.func,
  actionComponent: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
};

export default BarChart;
