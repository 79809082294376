import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ArrowCircleLeft = ({color, fontSize = '24px', ...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{fontSize: fontSize || '24px'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M12 7.99219L8 11.9922M8 11.9922L12 15.9922M8 11.9922H16M22 11.9922C22 17.515 17.5228 21.9922 12 21.9922C6.47715 21.9922 2 17.515 2 11.9922C2 6.46934 6.47715 1.99219 12 1.99219C17.5228 1.99219 22 6.46934 22 11.9922Z"
          stroke={color || '#98A2B3'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ArrowCircleLeft;
