import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MessageChatCircle = ({color = '#000', fontSize = '24px', ...props}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      sx={{fontSize: fontSize || '24px', cursor: 'pointer'}}>
      <g id="messageChatCircle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none">
          <path
            d="M13.7477 5.67002C13.182 4.34577 12.1765 3.25746 10.901 2.58907C9.62553 1.92068 8.15839 1.71322 6.7476 2.00177C5.33682 2.29033 4.06895 3.05718 3.15834 4.17269C2.24773 5.28821 1.75025 6.68394 1.75 8.12393V13.2802C1.75 13.5703 1.86523 13.8485 2.07035 14.0536C2.27547 14.2587 2.55367 14.3739 2.84375 14.3739H7.27109C7.75882 15.4877 8.56026 16.4354 9.57758 17.1013C10.5949 17.7673 11.7841 18.1226 13 18.1239H18.1562C18.4463 18.1239 18.7245 18.0087 18.9296 17.8036C19.1348 17.5985 19.25 17.3203 19.25 17.0302V11.8739C19.2497 10.346 18.6897 8.87113 17.6759 7.72803C16.6621 6.58493 15.2646 5.8528 13.7477 5.67002ZM3 8.12393C3 7.13502 3.29324 6.16833 3.84265 5.34608C4.39206 4.52383 5.17295 3.88297 6.08658 3.50453C7.00021 3.1261 8.00555 3.02708 8.97545 3.22001C9.94536 3.41293 10.8363 3.88913 11.5355 4.5884C12.2348 5.28766 12.711 6.17857 12.9039 7.14848C13.0969 8.11838 12.9978 9.12372 12.6194 10.0373C12.241 10.951 11.6001 11.7319 10.7779 12.2813C9.9556 12.8307 8.98891 13.1239 8 13.1239H3V8.12393ZM18 16.8739H13C12.1159 16.8729 11.2477 16.638 10.4838 16.193C9.71976 15.7481 9.0871 15.1089 8.65 14.3403C9.50603 14.2515 10.3345 13.9867 11.0833 13.5625C11.8321 13.1383 12.4852 12.5639 13.0015 11.8754C13.5178 11.1868 13.8862 10.399 14.0836 9.56128C14.2809 8.7236 14.303 7.85415 14.1484 7.00752C15.2447 7.26625 16.2216 7.88758 16.9206 8.77083C17.6197 9.65409 18 10.7475 18 11.8739V16.8739Z"
            fill={color}
          />
        </svg>
      </g>
    </SvgIcon>
  );
};

export default MessageChatCircle;
