import {Services} from 'core';
import * as TYPES from './constants';
import {API} from 'config/srm.config';
import {
  requestStudentResume,
  receiveStudentResume,
  handleStudentResumeError,
} from './actions';

const initialState = {
  data: [],
  error: null,
  request: null,
};

const services = new Services();

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_STUDENT_RESUME:
      return {...state, request: true, error: false};

    case TYPES.RECEIVE_STUDENT_RESUME:
      return {
        ...state,
        error: false,
        request: false,
        data: action.payload,
      };

    case TYPES.STUDENT_RESUME_ERROR:
      return {...state, error: true, request: false};

    case TYPES.CLEAR_STUDENT_RESUME:
      return {...initialState};
    default:
      return state;
  }
};

export const fetchStudentResume = (userName = '', download = false) => (
  dispatch,
  getState
) => {
  dispatch(requestStudentResume());
  try {
    const {
      auth: {
        access: {token},
      },
    } = getState();
    let endPoint = `${API.student.student_resume}?username=${userName}`;
    return services.fetchRecords(endPoint, token).then(res => {
      if (Array.isArray(res)) {
        dispatch(receiveStudentResume(res[0] ? res[0] : null));
      } else dispatch(handleStudentResumeError(res));
    });
  } catch (err) {
    console.log(err);
    dispatch(handleStudentResumeError(err));
  }
};

export const downloadStudentResume = (resumeId = '', username = '') => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  services.fetchRecords(
    `${
      API.student.student_resume
    }/${resumeId}?action=download&username=${username}`,
    token
  );
};
