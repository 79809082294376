import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Placeholder = ({color = '#000', fontSize = '24px', ...props}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      sx={{fontSize: fontSize || '24px', cursor: 'pointer'}}>
      <g id="placeHolder">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </g>
    </SvgIcon>
  );
};

export default Placeholder;
