import {uniq} from 'lodash';
import moment from 'moment';
import {IMG_CLOUDINARY} from 'config/srm.config';
import {
  getGridStringOperators,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-pro';
import {defaulGoBanner} from 'sharedResources/assets/images';
import {loadTokens} from 'redux/localStorage';

export const themeColorMode = (theme, lightColor, background) =>
  theme.palette.mode === 'light' ? lightColor : background;

export const filterStringOperators =
  getGridStringOperators().filter(
    operator =>
      operator.value === 'equals' ||
      operator.value === 'contains' ||
      operator.value === 'startsWith'
  ) || [];

export const filterStringContainsOperator =
  getGridStringOperators().filter(operator => operator.value === 'contains') ||
  [];

export const filterSingleSelectOperators =
  getGridSingleSelectOperators().filter(operator => operator.value === 'is') ||
  [];

const renameKey = (oldkey, newkey, obj) => {
  const {[oldkey]: old, ...rest} = obj;
  return {
    ...rest,
    [newkey]: new Date(old),
  };
};

const renameKeys = (keys, obj) => {
  Object.keys(keys).forEach(
    oldkey => (obj = renameKey(oldkey, keys[oldkey], obj))
  );
  return obj;
};

const transformEvents = events =>
  events.map(evt => renameKeys({start_date: 'start', end_date: 'end'}, evt));

const personalEvent = (events, srm_user_uuid) => {
  return events.filter(event => srm_user_uuid === event.created_by_user);
};

const filterEvent = (events, srm_user_uuid, filter) =>
  filter === 'personal' ? personalEvent(events, srm_user_uuid) : events;

export const extractFileName = (url, useCloudinaryFormat) => {
  if (url) {
    const tokens = url.split('/');
    const tokenlen = tokens.length;
    if (useCloudinaryFormat && url.includes(IMG_CLOUDINARY)) {
      return tokens && tokenlen && tokens[tokenlen - 2];
    }
    return tokens && tokenlen && tokens[tokenlen - 1].split('.')[0];
  }
};

export const dragDirection = (
  dragIndex,
  hoverIndex,
  initialClientOffset,
  clientOffset,
  sourceClientOffset
) => {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
  const hoverClientY = clientOffset.y - sourceClientOffset.y;
  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward';
  }
  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward';
  }
};

export const deleteObjProps = (Obj, propertiesArray) => {
  propertiesArray &&
    propertiesArray.length &&
    propertiesArray.forEach(prop => {
      delete Obj[prop];
    });
};

export const extractFileExtension = filePath => {
  let extension = null;
  if (filePath && isString(filePath) && filePath.includes('.')) {
    extension = filePath.split('.').pop();
  }
  return extension;
};

export const formatFileSize = size => {
  if (size < 1024) {
    return size + ' B';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  } else {
    return (size / (1024 * 1024)).toFixed(2) + ' MB';
  }
};
export const fileTypeIcon = fileType => {
  const supportedTypesIcon = {
    file: 'file',
    jpg: 'file-jpg',
    jpeg: 'file-jpg',
    png: 'file-png',
    pdf: 'file-pdf',
    txt: 'file-text',
    'application/pdf': 'file-pdf',
    'image/jpeg': 'file-jpg',
    'image/png': 'file-jpg',
    'text/plain': 'file-text',
  };

  if (fileType && isString(fileType) && fileType.includes('.')) {
    fileType = fileType.split('.').pop();
  }
  const typeFile = fileType && fileType.toLowerCase();
  return supportedTypesIcon[typeFile] || supportedTypesIcon['file'];
};

export const windowPrint = () => window.print();

export const filterEvents = (events, srm_user_uuid, filter) =>
  filterEvent(transformEvents(events), srm_user_uuid, filter);

export const extractUuidArray = arrayOfObj => {
  return (
    arrayOfObj &&
    arrayOfObj.map(obj => {
      return obj.uuid;
    })
  );
};

export const containsObject = (key, val, list) => {
  let value = false;
  list &&
    list.forEach(item => {
      if (item[key] === val) {
        value = true;
      }
    });
  return value;
};

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const customSort = (property, order) => {
  var sortOrder = 1;
  if (order === 'descending') {
    sortOrder = -1;
  }

  return function(a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const dynamicSort = property => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function(a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};

export const sortArrayOfObjPropertyBased = (obj, propName) => {
  return obj && [...obj].sort(dynamicSort(propName));
};

export const newSortArrayOfObjPropertyBased = (obj, propName, order) => {
  return (
    obj &&
    [...obj]
      .map(item => {
        item['units'] = parseFloat(item['units']);
        return item;
      })
      .sort(customSort(propName, order))
  );
};

export const isObject = value =>
  value && typeof value === 'object' && value instanceof Object;

export const isBoolean = value => value && typeof value === 'boolean';

export const isFile = value =>
  value && typeof value === 'object' && value instanceof File;

export const isString = value =>
  typeof value === 'string' || value instanceof String;

export const isNumber = value => typeof value === 'number' && isFinite(value);

export const isArray = value =>
  value && typeof value === 'object' && value.constructor === Array;

export const isFunction = value => typeof value === 'function';

export const isDate = value => value instanceof Date;

export const isError = value =>
  value instanceof Error && typeof value.message !== 'undefined';

export const isSameDay = (start, end) => {
  const isSameDay = end && start && moment(end).isSame(moment(start), 'day');
  return isSameDay;
};

export const isAllDayEvent = (start, end) => {
  const endDateHr = end && moment(end).get('hour');
  const endDateMin = end && moment(end).get('minute');
  return isSameDay(start, end) && endDateHr === 23 && endDateMin === 59;
};

export const monthlyEventMetaData = (
  allevents,
  eventMetaDataFn,
  year,
  month
) => {
  if (!(allevents && Object.keys(allevents).length)) {
    return;
  }
  const monthEventData =
    allevents[year] && allevents[year][month] ? allevents[year][month] : [];
  return eventMetaDataFn(monthEventData);
};

export const prepareEventMetaData = events => {
  if (!events) {
    return;
  }

  let active = 0,
    completed = 0,
    scheduled = 0,
    alert = 0;

  events.forEach(event => {
    // if (moment(event.start).isSameOrAfter()) {
    //   active++;
    // }
    // if (moment().diff(event.start) <= 0) {
    //   alert++;
    // }
    if (moment().isBetween(event.start, event.end)) {
      active++;
    }
    if (moment().isBetween(event.alert_date, event.start)) {
      alert++;
    }
    if (moment(event.start).isAfter()) {
      scheduled++;
    }
    if (moment(event.end).isBefore()) {
      completed++;
    }
  });
  return {active, completed, scheduled, alert};
};

export const getPhysicalAddress = (addresses = []) =>
  addresses && addresses.find(({type}) => type === 'physical');

export const getMailingAddress = (addresses = []) =>
  addresses && addresses.find(({type}) => type === 'mailing');

export const getCombinationsForK = (length, k) => {
  var combi = [];
  var temp = [];
  const valuesArray = new Array(length).fill(0).map((it, i) => i);
  var slent = Math.pow(2, valuesArray.length);
  for (var i = 0; i < slent; i++) {
    temp = [];
    for (var j = 0; j < valuesArray.length; j++) {
      if (i & Math.pow(2, j)) {
        temp.push(valuesArray[j]);
      }
    }
    if (temp.length > 0) {
      combi.push(temp);
    }
  }
  combi.sort((a, b) => a.length - b.length);
  return combi.filter(i => i.length === k);
};

// Used to format duration string in pathways
export const durationString = data => {
  if (data) {
    const durationKeys = ['year', 'month', 'week', 'day', 'hour'];
    let displayString = '';
    durationKeys.forEach((key, idx) => {
      const composed_key = `duration_${key}s`;
      const DATA = data[composed_key];
      if (DATA) {
        const dispKey = ` - ${key[0].toUpperCase()}${key.slice(1)}`;
        displayString += `${
          DATA ? (DATA === 1 ? DATA + dispKey : DATA + `${dispKey}s`) : ''
        }`;

        for (let i = idx + 1; i < durationKeys.length; i++) {
          const composed_key = `duration_${durationKeys[i]}s`;
          const DATA = data[composed_key];
          if (DATA) {
            displayString += idx === durationKeys.length - 2 ? ' & ' : ', ';
            break;
          }
        }
      }
    });
    return displayString;
  }
  return '';
};

export const getCloudinaryImage = (
  publicID,
  type = 'banner',
  noDefaultImage
) => {
  const defaultImage =
    type === 'logo'
      ? 'd_no_collage_logo_sozbbx.png'
      : 'GO_Profile_Banner_oquifz.png';
  // d_defaultbannerprofile_hnyuqa.jpg;
  return (
    publicID &&
    encodeURI(
      `${IMG_CLOUDINARY}/${noDefaultImage ? '' : defaultImage}/${publicID}.jpg`
    )
  );
};

export const getBanner = (publicID, ext = 'jpg') => {
  if (publicID) {
    return `${IMG_CLOUDINARY}/q_60/d_defaultlogoimage_zot4ua.svg/${publicID}.${ext}`;
  } else {
    return `${IMG_CLOUDINARY}/q_60/d_defaultlogoimage_zot4ua.svg/defaultCard_xdoa5p.png`;
  }
};

export const getGoBanner = (publicID, config) => {
  const {height = 250, width = 1250, quality = 60, dpr = '2.0'} = config || {};
  let configString = `c_fill,g_auto,h_${height},q_${quality},dpr_${dpr},w_${width}`;
  if (config && isObject(config)) {
    Object.keys(config).map(key => {
      if (
        key === 'e_brightness' ||
        key === 'e_auto_contrast' ||
        key === 'e_auto_color' ||
        key === 'e_auto_saturation'
      ) {
        const property = key.substring(2);
        if (config[key] === 'auto') {
          configString += `,e_auto_${property}`;
        } else {
          configString += `,e_${property}:${config[key]}`;
        }
      }
      if (key === 'e_sharpen') {
        configString += `,e_sharpen:${config[key]}`;
      }
      if (key === 'e_vibrance') {
        configString += `e_vibrance:${config[key]}`;
      }
    });
  }

  if (publicID) {
    return `${IMG_CLOUDINARY}/${configString}/y_-0.5/d_defaultbannerprofile_hnyuqa.jpg/${publicID}.jpg`;
  } else {
    return defaulGoBanner;
  }
};

export const getCardImgSet = (publicID, type) => {
  let defaultImage = null;
  const defaultBriefcaseBanner = `briefcasedefault_rtv4h0`;
  const defaultBookmarkBanner = `bookmarkdefault_qmpimn`;
  if (type && type === 'occupation') {
    defaultImage = defaultBookmarkBanner;
  }
  if (type && type === 'appliedWorkforce') {
    defaultImage = defaultBriefcaseBanner;
  }

  let srcSet = [];
  if (publicID) {
    let fallBack = 'd_no_pathway_banner_rjrcim.jpg';
    if (type && type === 'occupation') {
      fallBack = 'd_bookmarkdefault_qmpimn.jpg';
    }
    if (type && type === 'appliedWorkforce') {
      fallBack = 'd_briefcasedefault_rtv4h0.jpg';
    }

    const sizes = ['', 480, 960, 1440];
    for (let i = 1; i <= 3; i++) {
      const path = `${IMG_CLOUDINARY}/c_fill,dpr_${i}.0,g_auto,h_150,q_auto,w_250/${fallBack}/${publicID}.jpg ${
        sizes[i]
      }w`;
      srcSet.push(path);
    }
    defaultImage = `${IMG_CLOUDINARY}/c_fill,e_blur:75,q_0.5,w_20/${fallBack}/${publicID}.jpg`;
  }
  return {
    srcSet: srcSet.join(','),
    normalImage: (srcSet && srcSet.length && srcSet[1]) || '',
    defaultImage,
  };
};

// e.g. : Generate numbers range 1..10 with step of 2 = generateRange(1,10,2)
export const generateRange = (start, stop, step, isString) =>
  Array.from({length: (stop - start) / step + 1}, (_, i) =>
    isString ? `${start + i * step}` : start + i * step
  );

export const capitalizeFirstLetter = str => {
  return str.replace(/^\w/, c => c.toUpperCase());
};

export const numberInUSFormat = (number, param) => {
  let config = {};
  if (param?.minimumIntegerDigits) {
    config['minimumIntegerDigits'] = param?.minimumIntegerDigits || 1;
  }
  if (param?.maximumFractionDigits) {
    config['maximumFractionDigits'] = param?.maximumFractionDigits;
  }
  const nfObject = new Intl.NumberFormat('en-US', config);
  return nfObject.format(number);
};

export const transformOpportunityData = data => {
  let transformedData = [];
  if (data && isArray(data)) {
    transformedData = data.map(
      (
        {
          student_id,
          apply_date = '-',
          skills_matched,
          student_details: {
            cognito_username,
            phone_number = '-',
            first_name,
            last_name,
            email = '-',
          },
        },
        idx
      ) => {
        return {
          key: idx,
          name: `${first_name} ${last_name}`,
          first_name,
          last_name,
          date_applied: apply_date,
          apply_date,
          mobile: phone_number,
          cognito_username,
          student_uuid: student_id,
          skills_matched: `${skills_matched || '0'}%`,
          skills_match: `${skills_matched || '0'}%`,
          email,
        };
      }
    );
  }
  return transformedData;
};

export const extractSkillsfromCourses = data => {
  let Skills = [];
  if (data && Array.isArray(data) && data.length) {
    data.forEach(d => {
      d.courses &&
        Array.isArray(d.courses) &&
        d.courses.length &&
        d.courses.forEach(course => {
          if (course.skills_learned && course.skills_learned.length) {
            Skills = [...Skills, ...course.skills_learned];
          }
        });
    });
  }
  return Skills;
};
export const combinedUniqueSkillsListForProgram = pathData => {
  let CombineSkills = [];
  if (pathData.skills_learned && pathData.skills_learned.length) {
    pathData.skills_learned.forEach(skill => {
      CombineSkills = [...CombineSkills, skill.name];
    });
  }
  if (pathData.terms && pathData.terms.length) {
    CombineSkills = [
      ...CombineSkills,
      ...extractSkillsfromCourses(pathData.terms),
    ];
  }
  if (pathData.segment && pathData.segment.length) {
    CombineSkills = [
      ...CombineSkills,
      ...extractSkillsfromCourses(pathData.segment),
    ];
  }
  if (pathData.segment_groups && pathData.segment_groups.length) {
    pathData.segment_groups.forEach(seg_group => {
      CombineSkills = [
        ...CombineSkills,
        ...extractSkillsfromCourses(seg_group.segments),
      ];
    });
  }
  if (pathData.ge_path_details) {
    if (pathData.ge_path_details.segments) {
      CombineSkills = [
        ...CombineSkills,
        ...extractSkillsfromCourses(pathData.ge_path_details.segments),
      ];
    }
    if (pathData.ge_path_details.segment_groups.length) {
      pathData.ge_path_details.segment_groups.forEach(seg_group => {
        CombineSkills = [
          ...CombineSkills,
          ...extractSkillsfromCourses(seg_group.segments),
        ];
      });
    }
  }
  return uniq(CombineSkills);
};

export const formatPhoneNumber = phoneNumber => {
  try {
    let phoneNumberString = phoneNumber.toString();
    return (
      phoneNumberString &&
      phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    );
  } catch (e) {
    return '';
  }
};

export const getAwardType = (achievementOptions, id) => {
  let value = id;
  if (Array.isArray(achievementOptions)) {
    const d = achievementOptions.filter(i => i.uuid === id)[0];
    if (d) {
      value = d.options;
    }
  }
  return value;
};

export const formatViewDate = date => {
  try {
    const dayObj = moment(new Date(date));
    return dayObj.format('MM/DD/YYYY');
  } catch (e) {
    return '';
  }
};

export const getLicenseVersions = (licenseTypes, portal, profile) => {
  if (licenseTypes && licenseTypes.data && licenseTypes.data.version_details) {
    const {version_details} = licenseTypes.data;
    const {license_access_name} = profile || {};
    let version;
    version_details.every(ver => {
      Object.keys(ver).forEach(key => {
        if (key === portal) {
          version = ver[key];
        }
      });
      if (version) return false;
      return true;
    });
    if (version && version[license_access_name])
      return version[license_access_name];
    return false;
  }
};

// get the custom and predefined benefits

export const initializeBenefits = (benefitsArr, benefits = []) => {
  const selectedBenefits = benefits
    ?.map(ele => benefitsArr?.find(obj => obj.value === ele))
    .filter(obj => obj);
  const customBenefits = benefits
    ?.filter(ele => !benefitsArr?.some(obj => obj.value === ele))
    .map(ele => ({label: ele, value: ele}));
  return {selectedBenefits, customBenefits};
};

export const queryStringParse = data =>
  data
    .substring(1)
    .split('&')
    .map(item => {
      const idx = item.indexOf('=');
      const variableName = item.substring(0, idx);
      const variableValue = item.substring(idx + 1);
      return Object.assign(
        {},
        {
          [variableName]: variableValue,
        }
      );
    })
    .reduce(function(result, current) {
      return Object.assign(result, current);
    }, {});

export const calcUserManagementStatsData = data => {
  let total = (data && isArray(data) && data.length) || 0;
  let administrators =
    (data &&
      isArray(data) &&
      data.filter(item => item['role'] === 'Administrator').length) ||
    0;
  let analysts =
    (data &&
      isArray(data) &&
      data.filter(item => item['role'] === 'Analyst').length) ||
    0;
  let pending =
    (data &&
      isArray(data) &&
      data.filter(item => item['UserStatus'] === 'FORCE_CHANGE_PASSWORD')
        .length) ||
    0;

  return {total, administrators, analysts, pending};
};

export const sortEducationBydate = data => {
  return data.sort((a, b) => {
    if (a.school_end_date < b.school_end_date) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const sortProfessionalExperienceBydate = data => {
  let havingIsPresent = [];
  let notHavingIsPresent = [];
  data.forEach(ele => {
    if (ele.is_present) {
      havingIsPresent.push(ele);
    } else {
      notHavingIsPresent.push(ele);
    }
  });
  const sortedIsPresent = havingIsPresent.sort((a, b) => {
    if (a.start_date < b.start_date) return 1;
    else return -1;
  });
  const sortedNotIsPresent = notHavingIsPresent.sort((a, b) => {
    if (a.end_date < b.end_date) {
      return 1;
    } else {
      return -1;
    }
  });
  return [...sortedIsPresent, ...sortedNotIsPresent];
};

export const prepareAdditionalStudentDetails = (studentDetails, filters) => {
  const titles = [];
  const educations = [];
  const additionalInfo = [];
  const fieldOfStudies = [];
  const {
    spotlight = '',
    title: filteredTitles = [],
    skill: filteredSkills = [],
    field_of_study: filteredFOS = [],
    industry: filteredIndustries = [],
    locations: filteredLocations = [],
    education: filteredEducations = [],
  } = filters || {};
  const {
    address = {},
    open_to = '',
    related_industry = '',
    resume_data: {skills = [], work_exp = [], credentials = []} = {},
  } = studentDetails || {};
  const {city = '', state = ''} = address || {};
  if (work_exp && Array.isArray(work_exp) && work_exp?.length > 0) {
    sortProfessionalExperienceBydate(work_exp)?.map(
      ({
        job_title,
        is_present,
        company_name,
        end_date = '',
        start_date = '',
      }) => {
        const isTitleFiltered = filteredTitles.some(
          title => title === job_title
        );
        titles.push(
          `${
            isTitleFiltered
              ? `<span class='primary'>${job_title}</span>`
              : job_title
          } at ${company_name} (${moment(start_date).format('MMM YYYY')} - ${
            is_present ? 'Present' : moment(end_date).format('MMM YYYY')
          })`
        );
      }
    );
  }

  if (credentials && Array.isArray(credentials) && credentials?.length > 0) {
    const educationList = credentials;
    const educationListSorted = sortEducationBydate(
      educationList,
      'school_start_date',
      'school_end_date'
    );
    educationListSorted?.map(
      ({
        org_name = '',
        award_type = '',
        area_of_focus = '',
        school_end_date = '',
      }) => {
        let endDate = '';
        area_of_focus && fieldOfStudies.push(area_of_focus);
        if (school_end_date) {
          endDate = moment(school_end_date).format('MMM YYYY');
        }
        const isFuture = moment(school_end_date).isAfter(new Date());
        const isEducationFiltered = filteredEducations.some(
          edu => edu === award_type
        );
        educations.push(
          `${
            award_type
              ? isEducationFiltered
                ? `<span class='primary'>${award_type}</span> at `
                : `${award_type} at `
              : ''
          }${org_name} ${
            endDate
              ? ` - (${isFuture ? 'Expected' : 'Completed'}: ${endDate})`
              : ''
          }`
        );
      }
    );
  }

  titles &&
    Array.isArray(titles) &&
    titles?.length > 0 &&
    additionalInfo.push({
      label: 'Title',
      value: titles,
    });

  let spotlightValue = '';
  let isSpotlightFiltered = false;
  switch (open_to) {
    case 'a job': {
      spotlightValue = 'Open to Work';
      isSpotlightFiltered = spotlight === 'job';
      break;
    }
    case 'an internship': {
      spotlightValue = 'Open to Internship';
      isSpotlightFiltered = spotlight === 'internship';
      break;
    }
    case 'an apprenticeship': {
      spotlightValue = 'Open to Apprenticeship';
      isSpotlightFiltered = spotlight === 'apprenticeship';
      break;
    }
    default: {
      isSpotlightFiltered = false;
      spotlightValue = 'Open to Work';
    }
  }
  open_to &&
    additionalInfo.push({
      label: 'Spotlight',
      value: isSpotlightFiltered
        ? `<span class='primary'>${spotlightValue}</span>`
        : spotlightValue,
    });
  related_industry &&
    additionalInfo.push({
      label: 'Industry',
      value: filteredIndustries?.some(industry => industry === related_industry)
        ? `<span class='primary'>${related_industry}</span`
        : related_industry,
    });
  let studentLocation = (city && state && `${city}, ${state}`) || '';
  const isLocationFiltered = filteredLocations
    .map(loc => loc.replace(',', ', '))
    .some(location => {
      if (location.toLowerCase() === studentLocation.toLocaleLowerCase()) {
        studentLocation = location;
        return true;
      }
    });
  studentLocation &&
    additionalInfo.push({
      label: 'Location',
      value: isLocationFiltered
        ? `<span class='primary'>${capitalizeFirstLetter(
            studentLocation
          )}</span>`
        : capitalizeFirstLetter(studentLocation),
    });
  fieldOfStudies &&
    Array.isArray(fieldOfStudies) &&
    fieldOfStudies?.length > 0 &&
    additionalInfo.push({
      label: 'Field of Study',
      value: fieldOfStudies.map(item => ({
        label: item,
        isFiltered: filteredFOS?.some(fos => fos === item),
      })),
    });
  skills &&
    Array.isArray(skills) &&
    skills?.length > 0 &&
    additionalInfo.push({
      label: 'Skills',
      value: skills.map(skill => ({
        label: skill,
        isFiltered: filteredSkills?.some(itm => itm === skill),
      })),
    });
  educations &&
    Array.isArray(educations) &&
    educations?.length > 0 &&
    additionalInfo.push({
      value: educations,
      label: 'Education',
    });
  return additionalInfo;
};

export function countFilterMatchInData(filter, data) {
  let count = 0;
  // Iterate over each job title in work_exp
  for (let d of data) {
    // Check if any title from the title array is present in the job title
    for (let t of filter) {
      if (d === t) {
        count++;
        break; // Break to avoid counting the same job title multiple times
      }
    }
  }
  return count;
}

export const calculateTalentMatch = (filter, data) => {
  const {resume_data} = data || {};
  const {skills} = resume_data || {};
  let match = 100;
  // title
  if (filter?.skill && Array.isArray(filter.skill) && filter.skill.length > 0) {
    match = 75;
    const count = countFilterMatchInData(filter?.skill, skills);
    const matchPerc = ((count / filter.skill.length) * 100 * 25) / 100;
    match += Math.round(matchPerc);
  }
  return match;
};

export const getCompleteDate = dmy => {
  let year = null,
    month = null,
    date = null;
  if (dmy && isString(dmy)) {
    let d = new Date(dmy);
    year = d && d.getFullYear();
    month = d && d.getMonth() + 1;
    date = d && d.getDate();
  }
  return {
    date,
    month,
    year,
  };
};

export function transformHostnameToPath() {
  const {host: hostname, protocol} = window.location;
  let [host, port] = hostname.split(':'); // Split the hostname from the port, if any
  const parts = host.split('.');

  // Handle 'localhost' with or without port
  if (host === 'localhost') {
    return port ? `localhost:${port}` : 'localhost'; // Return 'localhost' as is if there is no subdomain
  }

  // If there's no subdomain, return the hostname as is
  if (parts.length <= 2 && !(parts.length === 2 && parts[1] === 'localhost')) {
    return hostname; // Return the original hostname if there's no subdomain
  }

  // For hostnames with subdomains, remove the first-level subdomain and append it to the path
  const subdomain = parts.shift(); // Remove the first-level subdomain
  const newHostname = parts.join('.'); // Rebuild the hostname without the first-level subdomain
  const path = `${newHostname}${port ? `:${port}` : ''}/${subdomain}`; // Return the new URL with the subdomain in the path
  return `${protocol}//${path}`;
}

export function hasWildCardSubdomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.indexOf('localhost') !== -1 && parts.length === 2) {
    return true;
  }
  if (parts.length === 4) return true;
  return false;
}
