import * as TYPES from './constants';

export const requestStudentOnboardStats = () => ({
  type: TYPES.REQUEST_STUDENT_ONBOARD_STATS,
});

export const receiveStudentOnboardStats = json => ({
  type: TYPES.RECEIVE_STUDENT_ONBOARD_STATS,
  payload: json,
});

export const handleStudentOnboardStatsError = err => ({
  type: TYPES.STUDENT_ONBOARD_STATS_ERROR,
  err,
});

export const requestStudentOnboardEvents = () => ({
  type: TYPES.REQUEST_STUDENT_ONBOARD_EVENTS,
});

export const receiveStudentOnboardEvents = json => ({
  type: TYPES.RECEIVE_STUDENT_ONBOARD_EVENTS,
  payload: json,
});

export const handleStudentOnboardEventsError = err => ({
  type: TYPES.STUDENT_ONBOARD_EVENTS_ERROR,
  err,
});

export const requestStudentOnboardCourseMap = () => ({
  type: TYPES.REQUEST_STUDENT_ONBOARD_COURSE_MAP,
});

export const receiveStudentOnboardCourseMap = json => ({
  type: TYPES.RECEIVE_STUDENT_ONBOARD_COURSE_MAP,
  payload: json,
});

export const handleStudentOnboardCourseMapError = err => ({
  type: TYPES.STUDENT_ONBOARD_COURSE_MAP_ERROR,
  err,
});

export const resetStudentOnboardCourseMap = err => ({
  type: TYPES.STUDENT_ONBOARD_COURSE_MAP_RESET,
});

export const requestStudentAssessments = () => ({
  type: TYPES.REQUEST_STUDENT_ASSESSMENTS,
});

export const receiveStudentAssessments = json => ({
  type: TYPES.RECEIVE_STUDENT_ASSESSMENTS,
  payload: json,
});

export const handleStudentAssessmentsError = err => ({
  type: TYPES.STUDENT_ASSESSMENTS_ERROR,
  err,
});

export const requestStudentProfile = () => ({
  type: TYPES.REQUEST_STUDENT_PROFILE,
});

export const receiveStudentProfile = json => ({
  type: TYPES.RECEIVE_STUDENT_PROFILE,
  payload: json,
});

export const handleStudentProfileError = err => ({
  type: TYPES.STUDENT_PROFILE_ERROR,
  err,
});

export const clearStudentProfileDetails = () => ({
  type: TYPES.CLEAR_STUDENT_PROFILE_DETAILS,
});

export const requestStudentRecommendedPlan = () => ({
  type: TYPES.REQUEST_STUDENT_RECOMMENDED_PLAN,
});

export const receiveStudentRecommendedPlan = json => ({
  type: TYPES.RECEIVE_STUDENT_RECOMMENDED_PLAN,
  payload: json[0],
});

export const handleStudentRecommendedPlanError = err => ({
  type: TYPES.STUDENT_RECOMMENDED_PLAN_ERROR,
  err,
});

//Fetch Student Profile
export const clearStudentProfileData = () => ({
  type: TYPES.CLEAR_STUDENT_PROFILE_DATA,
});

export const receiveStudentProfileData = json => ({
  type: TYPES.RECEIVE_STUDENT_PROFILE_DATA,
  payload: json,
});

export const requestStudentProfileData = () => ({
  type: TYPES.REQUEST_STUDENT_PROFILE_DATA,
});

export const handleStudentProfileDataError = err => ({
  type: TYPES.STUDENT_PROFILE_DATA_ERROR,
  err,
});

export const requestStudentSkillsStatus = () => ({
  type: TYPES.REQUEST_STUDENT_SKILS_STATUS,
});

export const receiveStudentSkillsStatus = json => ({
  type: TYPES.RECEIVE_STUDENT_SKILS_STATUS,
  payload: json,
});

export const handleStudentSkillsStatusError = err => ({
  type: TYPES.STUDENT_SKILS_STATUS_ERROR,
  err,
});

export const requestSkillsData = () => ({
  type: TYPES.REQUEST_SKILLS_DATA,
});

export const receiveSkillsData = json => ({
  type: TYPES.RECEIVE_SKILLS_DATA,
  payload: json,
});

export const handleSkillsDataError = err => ({
  type: TYPES.SKILLS_DATA_ERROR,
  err,
});

export const requestStudentWorkforce = () => ({
  type: TYPES.REQUEST_STUDENT_WORKFORCE,
});

export const receiveStudentWorkforce = json => ({
  type: TYPES.RECEIVE_STUDENT_WORKFORCE,
  payload: json,
});

export const handleStudentWorkforceError = err => ({
  type: TYPES.STUDENT_WORKFORCE_ERROR,
  err,
});

// Student saved paths
export const requestStudentSavedPaths = () => ({
  type: TYPES.REQUEST_STUDENT_SAVED_PATHS,
});

export const receiveStudentSavedPaths = json => ({
  type: TYPES.RECEIVE_STUDENT_SAVED_PATHS,
  payload: json,
});

export const handleStudentSavedPathsError = err => ({
  type: TYPES.STUDENT_SAVED_PATHS_ERROR,
  err,
});

export const resetStudentSavedPaths = err => ({
  type: TYPES.STUDENT_SAVED_PATHS_RESET,
});

// Student compared paths
export const requestStudentComparedPaths = () => ({
  type: TYPES.REQUEST_STUDENT_COMPARED_PATHS,
});

export const receiveStudentComparedPaths = json => ({
  type: TYPES.RECEIVE_STUDENT_COMPARED_PATHS,
  payload: json,
});

export const handleStudentComparedPathsError = err => ({
  type: TYPES.STUDENT_SAVED_COMPARED_ERROR,
  err,
});

export const resetStudentComparedPaths = err => ({
  type: TYPES.STUDENT_SAVED_COMPARED_RESET,
});

// Award Types for Student
export const requestAwardTypes = err => ({
  type: TYPES.STUDENT_AWARD_TYPES_LOAD,
});
export const receiveAwardTypes = json => ({
  type: TYPES.STUDENT_AWARD_TYPES_SUCCESS,
  payload: json,
});
export const awardTypesError = err => ({
  type: TYPES.STUDENT_AWARD_TYPE_ERROR,
  err,
});

// Cluster types
export const requestClusterTypes = () => ({
  type: TYPES.STUDENT_CLUSTER_TYPES_LOAD,
});

export const receiveClusterTypes = json => ({
  type: TYPES.STUDENT_CLUSTER_TYPES_SUCCESS,
  payload: json,
});

export const handleClusterTypesError = err => ({
  type: TYPES.STUDENT_CLUSTER_TYPES_ERROR,
  err,
});

// Resume List
export const requestResumeList = () => ({
  type: TYPES.REQUEST_STUDENT_RESUME_LIST,
});
export const receiveResumeList = data => ({
  type: TYPES.RECEIVE_STUDENT_RESUME_LISTS,
  payload: data,
});
export const resumeListError = err => ({
  type: TYPES.STUDENT_RESUME_LIST_ERROR,
  err,
});
export const clearResumeList = () => ({
  type: TYPES.CLEAR_STUDENT_RESUME_LIST,
});

// Privacy settings
export const requestPrivacySetting = () => ({
  type: TYPES.REQUEST_PRIVACY_SETTING,
});
export const receivePrivacySetting = json => ({
  type: TYPES.RECEIVE_PRIVACY_SETTING,
  payload: json,
});
export const handlePrivacySettingError = err => ({
  type: TYPES.HANDLE_PRIVACY_SETTING_ERROR,
  err,
});

// CareerNav
export const requestCareerNavUsers = () => ({
  type: TYPES.REQUEST_CAREERNAV_USERS,
});
export const receiveCareerNavUsers = json => ({
  type: TYPES.RECEIVE_CAREERNAV_USERS,
  payload: json,
});
export const careerNavUsersError = err => ({
  type: TYPES.CAREERNAV_USERS_ERROR,
  err,
});

// student opportunity
export const requestStudentOpportunityList = () => ({
  type: TYPES.REQUEST_STUDENT_OPPORTUNITY_LIST,
});

export const receiveStudentOpportunityList = json => ({
  type: TYPES.RECEIVE_STUDENT_OPPORTUNITY_LIST,
  payload: json,
});

export const receiveStudentOpportunityListError = err => ({
  type: TYPES.STUDENT_OPPORTUNITY_LIST_ERROR,
  err,
});

export const clearStudentOpportunityList = err => ({
  type: TYPES.RESET_STUDENT_OPPORTUNITY_LIST,
});

// student details
export const requestStudentProfileDetails = () => ({
  type: TYPES.REQUEST_STUDENT_PROFILE_DETAILS,
});

export const receiveStudentProfileDetails = json => ({
  type: TYPES.RECEIVE_STUDENT_PROFILE_DETAILS,
  payload: json,
});

export const handleStudentProfileDetailsError = err => ({
  type: TYPES.STUDENT_PROFILE_DETAILS_ERROR,
  err,
});

// students enrolled
export const requestStudentOnboardPaths = () => ({
  type: TYPES.REQUEST_STUDENT_ONBOARD_PATHS,
});

export const receiveStudentOnboardPaths = json => ({
  type: TYPES.RECEIVE_STUDENT_ONBOARD_PATHS,
  payload: json,
});

export const handleStudentOnboardPathsError = err => ({
  type: TYPES.STUDENT_ONBOARD_PATHS_ERROR,
  err,
});

export const resetStudentOnboardPaths = err => ({
  type: TYPES.STUDENT_ONBOARD_PATHS_RESET,
});

// Saved Careers
export const requestStudentSavedCareers = () => ({
  type: TYPES.REQUEST_STUDENT_SAVED_CAREERS,
});
export const receiveStudentSavedCareers = json => ({
  type: TYPES.RECEIVE_STUDENT_SAVED_CAREERS,
  payload: json,
});
export const handleStudentSavedCareersError = err => ({
  type: TYPES.STUDENT_SAVED_CAREERS_ERROR,
  err,
});
export const clearStudentSavedCareers = () => ({
  type: TYPES.STUDENT_SAVED_CAREERS_RESET,
});

// Survey Result
export const requestStudentSurveyResults = () => ({
  type: TYPES.REQUEST_STUDENT_SURVEY_RESULTS,
});
export const receiveStudentSurveyResults = json => ({
  type: TYPES.RECEIVE_STUDENT_SURVEY_RESULTS,
  payload: json,
});
export const handleStudentSurveyResultsError = err => ({
  type: TYPES.STUDENT_SURVEY_RESULTS_ERROR,
  err,
});
export const clearStudentSurveyResults = () => ({
  type: TYPES.STUDENT_SURVEY_RESULTS_RESET,
});

// Fit Survey Result
export const requestStudentFitSurveyResults = () => ({
  type: TYPES.REQUEST_STUDENT_FIT_SURVEY_RESULTS,
});
export const receiveStudentFitSurveyResults = json => ({
  type: TYPES.RECEIVE_STUDENT_FIT_SURVEY_RESULTS,
  payload: json,
});
export const handleStudentFitSurveyResultsError = err => ({
  type: TYPES.STUDENT_FIT_SURVEY_RESULTS_ERROR,
  err,
});
export const clearStudentFitSurveyResults = () => ({
  type: TYPES.STUDENT_FIT_SURVEY_RESULTS_RESET,
});

//Student Resume
export const requestStudentResume = () => ({
  type: TYPES.REQUEST_STUDENT_RESUME,
});
export const receiveStudentResume = json => ({
  type: TYPES.RECEIVE_STUDENT_RESUME,
  payload: json,
});
export const handleStudentResumeError = err => ({
  type: TYPES.STUDENT_RESUME_ERROR,
  err,
});

export const clearStudentResume = () => ({
  type: TYPES.CLEAR_STUDENT_RESUME,
});
