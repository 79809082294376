import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ExpandMore = ({filled, outLine, fontSize = '24px', ...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{fontSize: fontSize || '24px'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none">
        <path
          fill={filled}
          stroke-width="2"
          stroke-linecap="round"
          stroke={outLine || '#98A2B3'}
          stroke-linejoin="round"
          d="M5 8L10 13L15 8"
        />
      </svg>
    </SvgIcon>
  );
};

export default ExpandMore;
