import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const InfoCircle = ({
  background = '#fff',
  color = '#000',
  fontSize = '24px',
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      sx={{fontSize: fontSize || '24px', cursor: 'pointer'}}>
      <g id="info / Circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={background}>
          <path
            d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </g>
    </SvgIcon>
  );
};

export default InfoCircle;
