import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Download02 = ({color = '#000', fontSize = '24px', ...props}) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      sx={{fontSize: fontSize || '24px', cursor: 'pointer'}}>
      <g id="Download02">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path
            d="M21 21H3M18 11L12 17M12 17L6 11M12 17V3"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </g>
    </SvgIcon>
  );
};

export default Download02;
