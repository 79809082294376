import * as React from 'react';
import {styled} from '@mui/material/styles';
import {Add as AddIcon, Remove as RemoveIcon} from '@mui/icons-material';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import PropTypes from 'prop-types';

const getExpandIcon = (isExpanded, expandIcon) => {
  if (isExpanded) {
    return expandIcon || <RemoveIcon />;
  }
  return expandIcon || <AddIcon />;
};

const StyledAccordionSummary = styled(
  ({isExpanded, collpsable, expandIcon, titleDivider, ...restProps}) => (
    <AccordionSummary
      expandIcon={getExpandIcon(isExpanded, expandIcon)}
      {...restProps}
    />
  )
)(({collpsable, isExpanded, theme, titleDivider}) => ({
  borderBottom:
    titleDivider && isExpanded ? `1px solid ${theme.palette.divider}` : `none`,
  cursor: collpsable ? 'pointer' : 'default',
  '& .MuiAccordionSummary-content': {
    cursor: collpsable ? 'pointer' : 'default',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: collpsable ? 'block' : 'none',
  },
}));

const CustomizedAccordions = props => {
  const {
    id,
    children,
    onChange,
    isExpand,
    expandIcon,
    controlled,
    elevation = 0,
    accordionTitle,
    component = 'h3',
    collpsable = false,
    titleDivider = true,
    defaultExpanded = false,
  } = props;
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  React.useEffect(
    () => {
      if (props.expanded !== undefined) setExpanded(props.expanded);
    },
    [props.expanded]
  );

  const handleChange = () => {
    collpsable && setExpanded(!expanded);
    if (collpsable && !expanded && onChange) {
      onChange(!expanded);
    }
  };

  return (
    <Accordion
      {...props}
      disableGutters
      elevation={elevation}
      expanded={controlled ? isExpand : expanded}
      slotProps={{heading: {component: component}}}
      onChange={controlled ? onChange : handleChange}>
      <StyledAccordionSummary
        isExpanded={expanded}
        collpsable={collpsable}
        expandIcon={expandIcon}
        id={`panel${id}-header`}
        titleDivider={titleDivider}
        aria-controls={`panel${id}-content`}>
        {accordionTitle}
      </StyledAccordionSummary>
      <AccordionDetails sx={{mb: '12px'}}>{children}</AccordionDetails>
    </Accordion>
  );
};

CustomizedAccordions.propTypes = {
  ...Accordion.propTypes,
  id: PropTypes.string,
  children: PropTypes.node,
  collpsable: PropTypes.bool,
  expandIcon: PropTypes.bool,
  titleDivider: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  accordionTitle: PropTypes.string,
};

export default CustomizedAccordions;
